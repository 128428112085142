// This file was automatically generated by the Soy compiler.
// Please don't edit this file by hand.
// source: ts/perspectives/dashboard/TeamscaleDashboardPerspectiveTemplate.soy

/**
 * @fileoverview Templates in namespace ts.perspectives.dashboard.TeamscaleDashboardPerspectiveTemplate.
 * @suppress {missingRequire} TODO(b/152440355)
 */



import * as $googSoy from 'ts-closure-library/lib/soy/soy';
import * as google from 'ts-closure-library/lib/google';
import * as goog_soy_data from 'soyutils/soyutils_usegoog';
import * as soy from 'soyutils/soyutils_usegoog';
import * as $soy$ts$commons$LinkTemplate from './../../commons/LinkTemplate.soy.generated.js';
import * as $soy$ts$commons$UIUtilsTemplate from './../../commons/UIUtilsTemplate.soy.generated.js';
import * as $soy$ts$perspectives$activity$commons$ActivityCommonsTemplate from './../activity/commons/ActivityCommonsTemplate.soy.generated.js';
import * as $soy$ts$perspectives$dashboard$widgets$parameters$WidgetParameterTemplate from './widgets/parameters/WidgetParameterTemplate.soy.generated.js';
import * as $soy$ts$perspectives$user$TeamscaleUserPerspectiveTemplate from './../user/TeamscaleUserPerspectiveTemplate.soy.generated.js';


/**
 * @param {!$dashboardUpload.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $dashboardUpload = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.perspectives.dashboard.TeamscaleDashboardPerspectiveTemplate.dashboardUpload']) {
    return soy.$$stubsMap['ts.perspectives.dashboard.TeamscaleDashboardPerspectiveTemplate.dashboardUpload'](opt_data, $ijData);
  }
  /** @type {?} */
  const csrfToken = opt_data.csrfToken;
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<form id="dashboard-upload-form" enctype="multipart/form-data"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.dashboard.TeamscaleDashboardPerspectiveTemplate.dashboardUpload ts/perspectives/dashboard/TeamscaleDashboardPerspectiveTemplate.soy:23"' : '') + '>' + $soy$ts$commons$UIUtilsTemplate.csrfTokenInput(/** @type {?} */ (opt_data), $ijData) + $soy$ts$commons$UIUtilsTemplate.fileUpload$(soy.$$internalCallMarkerDoNotUse, $ijData, 'dashboardDescriptor', 'Select Files', undefined, undefined, '.tsdashboard, .tstemplate', true) + '</form>');
};
export { $dashboardUpload as dashboardUpload };
/**
 * @typedef {{
 *  csrfToken: ?,
 * }}
 */
$dashboardUpload.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $dashboardUpload.soyTemplateName = 'ts.perspectives.dashboard.TeamscaleDashboardPerspectiveTemplate.dashboardUpload';
}


/**
 * @param {!$templateEditList.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 */
const $templateEditList = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $templateEditList$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.templates, opt_data.currentUser, opt_data.isDashboardAdmin);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {!Array<?>} templates
 * @param {?} currentUser
 * @param {?} isDashboardAdmin
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $templateEditList$ = function($$areYouAnInternalCaller, $ijData, templates, currentUser, isDashboardAdmin) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.perspectives.dashboard.TeamscaleDashboardPerspectiveTemplate.templateEditList']) {
    return soy.$$stubsMap['ts.perspectives.dashboard.TeamscaleDashboardPerspectiveTemplate.templateEditList']({templates: templates, currentUser: currentUser, isDashboardAdmin: isDashboardAdmin}, $ijData);
  }
  soy.assertParamType(Array.isArray(templates), 'templates', templates, '@param', '!Array<?>');
  let $output = '<div class="ui container max80"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.dashboard.TeamscaleDashboardPerspectiveTemplate.templateEditList ts/perspectives/dashboard/TeamscaleDashboardPerspectiveTemplate.soy:46"' : '') + '><h2>Dashboard Templates</h2>';
  if (templates.length == 0) {
    $output += '<div class="ui message">No templates available. Use an existing dashboard\'s menu to save it as a template.</div>';
  } else {
    $output += '<table class="ui striped table"><thead><tr><th></th><th class="four wide">Owner</th><th>Actions</th></tr></thead><tbody>';
    const entry34045List = templates;
    const entry34045ListLen = entry34045List.length;
    for (let entry34045Index = 0; entry34045Index < entry34045ListLen; entry34045Index++) {
      const entry34045Data = entry34045List[entry34045Index];
      $output += '<tr><td><div class="small ui header">' + soy.$$escapeHtml(entry34045Data[/** @type {?} */ ('name')]) + '</div><p class="muted description">' + soy.$$escapeHtml(entry34045Data[/** @type {?} */ ('description')]) + '</p></td><td>' + $soy$ts$perspectives$user$TeamscaleUserPerspectiveTemplate.roundAvatar$(soy.$$internalCallMarkerDoNotUse, $ijData, entry34045Data[/** @type {?} */ ('owner')], entry34045Data[/** @type {?} */ ('resolvedOwner')]) + ' </td><td style="text-align: right"><div class="ui small basic icon buttons">' + (soy.$$equals(currentUser, entry34045Data[/** @type {?} */ ('owner')]) || soy.$$coerceToBoolean(isDashboardAdmin) ? '<button' + soy.$$whitespaceHtmlAttributes($soy$ts$commons$UIUtilsTemplate.tooltip$(soy.$$internalCallMarkerDoNotUse, $ijData, 'Edit Template')) + ' data-templatename="' + soy.$$escapeHtmlAttribute(entry34045Data[/** @type {?} */ ('owner')]) + '/' + soy.$$escapeHtmlAttribute(entry34045Data[/** @type {?} */ ('name')]) + '" class="ui icon button edit-template"><i class="edit icon"></i></button><button' + soy.$$whitespaceHtmlAttributes($soy$ts$commons$UIUtilsTemplate.tooltip$(soy.$$internalCallMarkerDoNotUse, $ijData, 'Delete Template')) + ' data-templatename="' + soy.$$escapeHtmlAttribute(entry34045Data[/** @type {?} */ ('owner')]) + '/' + soy.$$escapeHtmlAttribute(entry34045Data[/** @type {?} */ ('name')]) + '" class="ui icon button delete-template"><i class="trash icon"></i></button>' : '') + '<button' + soy.$$whitespaceHtmlAttributes($soy$ts$commons$UIUtilsTemplate.tooltip$(soy.$$internalCallMarkerDoNotUse, $ijData, 'Export Template')) + ' data-templatename="' + soy.$$escapeHtmlAttribute(entry34045Data[/** @type {?} */ ('owner')]) + '/' + soy.$$escapeHtmlAttribute(entry34045Data[/** @type {?} */ ('name')]) + '" class="ui icon button export-template"><i class="download icon"></i></button></div></td></tr>';
    }
    $output += '</tbody></table>';
  }
  $output += '</div>';
  return soy.VERY_UNSAFE.ordainSanitizedHtml($output);
};
export { $templateEditList as templateEditList };
export { $templateEditList$ as templateEditList$ };
/**
 * @typedef {{
 *  templates: !Array<?>,
 *  currentUser: ?,
 *  isDashboardAdmin: ?,
 * }}
 */
$templateEditList.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $templateEditList.soyTemplateName = 'ts.perspectives.dashboard.TeamscaleDashboardPerspectiveTemplate.templateEditList';
}


/**
 * @param {!$createFromTemplateList.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 */
const $createFromTemplateList = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $createFromTemplateList$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.templates, opt_data.project);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {!Array<?>} templates
 * @param {?} project
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $createFromTemplateList$ = function($$areYouAnInternalCaller, $ijData, templates, project) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.perspectives.dashboard.TeamscaleDashboardPerspectiveTemplate.createFromTemplateList']) {
    return soy.$$stubsMap['ts.perspectives.dashboard.TeamscaleDashboardPerspectiveTemplate.createFromTemplateList']({templates: templates, project: project}, $ijData);
  }
  soy.assertParamType(Array.isArray(templates), 'templates', templates, '@param', '!Array<?>');
  let $output = $soy$ts$commons$UIUtilsTemplate.alerts(null, $ijData) + '<form method="GET" id="dashboard-template-form" action="api/dashboards/from-template"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.dashboard.TeamscaleDashboardPerspectiveTemplate.createFromTemplateList ts/perspectives/dashboard/TeamscaleDashboardPerspectiveTemplate.soy:110"' : '') + '><h4>Dashboard Name:</h4><div class="ui input"><input id="dashboard_name_input_field" name="dashboardname" type="text" size="30"></div><h4>Template</h4><div class="fixed-thead-scrollable-table"><table class="ui small table"><thead><tr><th></th><th>Name</th><th>Description</th></tr></thead><tbody>';
  const entry34268List = templates;
  const entry34268ListLen = entry34268List.length;
  for (let entry34268Index = 0; entry34268Index < entry34268ListLen; entry34268Index++) {
    const entry34268Data = entry34268List[entry34268Index];
    $output += '<tr class="template-entry" data-index="' + soy.$$escapeHtmlAttribute(entry34268Index) + '"><td><input type="radio"' + (entry34268Index == 0 ? ' checked="checked"' : '') + ' id="template' + soy.$$escapeHtmlAttribute(entry34268Index) + '" name="template" value="' + soy.$$escapeHtmlAttribute(entry34268Data[/** @type {?} */ ('owner')]) + '/' + soy.$$escapeHtmlAttribute(entry34268Data[/** @type {?} */ ('name')]) + '"></td><td>' + soy.$$escapeHtml(entry34268Data[/** @type {?} */ ('name')]) + '</td><td style="max-width: 500px">' + soy.$$escapeHtml(entry34268Data[/** @type {?} */ ('description')]) + '</td></tr>';
  }
  $output += '</tbody></table></div><h4>Project and Path</h4>' + $soy$ts$perspectives$dashboard$widgets$parameters$WidgetParameterTemplate.inputDialog$(soy.$$internalCallMarkerDoNotUse, $ijData, project) + '</form>';
  return soy.VERY_UNSAFE.ordainSanitizedHtml($output);
};
export { $createFromTemplateList as createFromTemplateList };
export { $createFromTemplateList$ as createFromTemplateList$ };
/**
 * @typedef {{
 *  templates: !Array<?>,
 *  project: ?,
 * }}
 */
$createFromTemplateList.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $createFromTemplateList.soyTemplateName = 'ts.perspectives.dashboard.TeamscaleDashboardPerspectiveTemplate.createFromTemplateList';
}


/**
 * @param {?Object<string, *>=} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $noTemplatesAvailableMessage = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.perspectives.dashboard.TeamscaleDashboardPerspectiveTemplate.noTemplatesAvailableMessage']) {
    return soy.$$stubsMap['ts.perspectives.dashboard.TeamscaleDashboardPerspectiveTemplate.noTemplatesAvailableMessage'](opt_data, $ijData);
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<h3' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.dashboard.TeamscaleDashboardPerspectiveTemplate.noTemplatesAvailableMessage ts/perspectives/dashboard/TeamscaleDashboardPerspectiveTemplate.soy:147"' : '') + '>No templates available</h3><p' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.dashboard.TeamscaleDashboardPerspectiveTemplate.noTemplatesAvailableMessage ts/perspectives/dashboard/TeamscaleDashboardPerspectiveTemplate.soy:148"' : '') + '>Use an existing dashboard\'s menu to save it as a template.</p>');
};
export { $noTemplatesAvailableMessage as noTemplatesAvailableMessage };
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $noTemplatesAvailableMessage.soyTemplateName = 'ts.perspectives.dashboard.TeamscaleDashboardPerspectiveTemplate.noTemplatesAvailableMessage';
}


/**
 * @param {!$sidebarHead.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 */
const $sidebarHead = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $sidebarHead$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.canSave, opt_data.dashboardName);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {boolean} canSave
 * @param {null|string|undefined=} dashboardName
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $sidebarHead$ = function($$areYouAnInternalCaller, $ijData, canSave, dashboardName) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.perspectives.dashboard.TeamscaleDashboardPerspectiveTemplate.sidebarHead']) {
    return soy.$$stubsMap['ts.perspectives.dashboard.TeamscaleDashboardPerspectiveTemplate.sidebarHead']({dashboardName: dashboardName, canSave: canSave}, $ijData);
  }
  soy.assertParamType(dashboardName == null || typeof dashboardName === 'string', 'dashboardName', dashboardName, '@param', 'null|string|undefined');
  soy.assertParamType(typeof canSave === 'boolean', 'canSave', canSave, '@param', 'boolean');
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div style="padding: 1em" class="sidebar-head"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.dashboard.TeamscaleDashboardPerspectiveTemplate.sidebarHead ts/perspectives/dashboard/TeamscaleDashboardPerspectiveTemplate.soy:158"' : '') + '>' + (dashboardName != null ? '<h3 class="ui small dividing header">Editing <span class="ui tiny teal header">' + soy.$$escapeHtml(dashboardName) + '</span></h3>' : '') + (canSave ? '<button class="ui button primary" id="button-save">Save</button>' : '') + '<button class="ui button ' + (!canSave ? 'primary' : '') + '" id="button-save-as">Save as</button><button class="ui button" id="button-cancel" href="' + soy.$$escapeHtmlAttribute(soy.$$filterNormalizeUri($soy$ts$commons$LinkTemplate.dashboard(/** @type {?} */ ({name: canSave ? dashboardName : null}), $ijData))) + '">Cancel</button></div>');
};
export { $sidebarHead as sidebarHead };
export { $sidebarHead$ as sidebarHead$ };
/**
 * @typedef {{
 *  dashboardName?: (null|string|undefined),
 *  canSave: boolean,
 * }}
 */
$sidebarHead.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $sidebarHead.soyTemplateName = 'ts.perspectives.dashboard.TeamscaleDashboardPerspectiveTemplate.sidebarHead';
}


/**
 * @param {!$editSidebarWidgets.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 */
const $editSidebarWidgets = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $editSidebarWidgets$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.widgetIds, opt_data.widgetNames);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {!Array<?>} widgetIds
 * @param {!Array<string>} widgetNames
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $editSidebarWidgets$ = function($$areYouAnInternalCaller, $ijData, widgetIds, widgetNames) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.perspectives.dashboard.TeamscaleDashboardPerspectiveTemplate.editSidebarWidgets']) {
    return soy.$$stubsMap['ts.perspectives.dashboard.TeamscaleDashboardPerspectiveTemplate.editSidebarWidgets']({widgetIds: widgetIds, widgetNames: widgetNames}, $ijData);
  }
  soy.assertParamType(Array.isArray(widgetIds), 'widgetIds', widgetIds, '@param', '!Array<?>');
  soy.assertParamType(Array.isArray(widgetNames), 'widgetNames', widgetNames, '@param', '!Array<string>');
  let $output = '<div class="ui form" style="padding: 0 1em"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.dashboard.TeamscaleDashboardPerspectiveTemplate.editSidebarWidgets ts/perspectives/dashboard/TeamscaleDashboardPerspectiveTemplate.soy:183"' : '') + '><div class="grouped fields">';
  const i34452_RangeEnd = widgetIds.length;
  const i34452ListLen = Math.max(0, Math.ceil((i34452_RangeEnd - 0) / 1));
  for (let i34452Index = 0; i34452Index < i34452ListLen; i34452Index++) {
    const i34452Data = 0 + i34452Index * 1;
    $output += '<div class="field"><button class="create-widget-button fluid ui button" data-widget="' + soy.$$escapeHtmlAttribute(widgetIds[/** @type {?} */ (i34452Data)]) + '">' + soy.$$escapeHtml(widgetNames[/** @type {?} */ (i34452Data)]) + '</button></div>';
  }
  $output += '</div></div>';
  return soy.VERY_UNSAFE.ordainSanitizedHtml($output);
};
export { $editSidebarWidgets as editSidebarWidgets };
export { $editSidebarWidgets$ as editSidebarWidgets$ };
/**
 * @typedef {{
 *  widgetIds: !Array<?>,
 *  widgetNames: !Array<string>,
 * }}
 */
$editSidebarWidgets.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $editSidebarWidgets.soyTemplateName = 'ts.perspectives.dashboard.TeamscaleDashboardPerspectiveTemplate.editSidebarWidgets';
}


/**
 * @param {!$saveDashboardDialogContent.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 */
const $saveDashboardDialogContent = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $saveDashboardDialogContent$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.dashboardName, opt_data.dashboardGroup, opt_data.description, opt_data.allowNameChange, opt_data.isTemplate);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {?} dashboardName
 * @param {?} dashboardGroup
 * @param {?} description
 * @param {?} allowNameChange
 * @param {?} isTemplate
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $saveDashboardDialogContent$ = function($$areYouAnInternalCaller, $ijData, dashboardName, dashboardGroup, description, allowNameChange, isTemplate) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.perspectives.dashboard.TeamscaleDashboardPerspectiveTemplate.saveDashboardDialogContent']) {
    return soy.$$stubsMap['ts.perspectives.dashboard.TeamscaleDashboardPerspectiveTemplate.saveDashboardDialogContent']({dashboardName: dashboardName, dashboardGroup: dashboardGroup, description: description, allowNameChange: allowNameChange, isTemplate: isTemplate}, $ijData);
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.dashboard.TeamscaleDashboardPerspectiveTemplate.saveDashboardDialogContent ts/perspectives/dashboard/TeamscaleDashboardPerspectiveTemplate.soy:211"' : '') + '>' + $soy$ts$commons$UIUtilsTemplate.alerts(null, $ijData) + '<form class="ui form"><div class="field"><label for="dashboard-name">Dashboard name</label><div class="ui input controls"><input type="text" id="dashboard-name" class="input-xlarge" value="' + soy.$$escapeHtmlAttribute(dashboardName) + '"' + (allowNameChange ? ' placeholder="Enter name" required' : ' disabled') + '/></div></div>' + (!isTemplate ? '<div class="field"><label for="dashboard-group">Dashboard group</label><div class="ui input controls"><input type="text" id="dashboard-group" class="input-xlarge" value="' + soy.$$escapeHtmlAttribute(dashboardGroup != null ? dashboardGroup : '') + '"' + (allowNameChange ? ' placeholder="Enter group (Optional)"' : ' disabled') + '/></div></div>' : '') + '<div class="field"><label for="change-description">Description</label><div class="controls"><div class="ui form"><div class="field"><textarea rows="4" class="input-xlarge" id="change-description" placeholder="Describe the content or the changes">' + soy.$$escapeHtmlRcdata(description) + '</textarea></div></div></div></div></form></div>');
};
export { $saveDashboardDialogContent as saveDashboardDialogContent };
export { $saveDashboardDialogContent$ as saveDashboardDialogContent$ };
/**
 * @typedef {{
 *  dashboardName: ?,
 *  dashboardGroup: ?,
 *  description: ?,
 *  allowNameChange: ?,
 *  isTemplate: ?,
 * }}
 */
$saveDashboardDialogContent.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $saveDashboardDialogContent.soyTemplateName = 'ts.perspectives.dashboard.TeamscaleDashboardPerspectiveTemplate.saveDashboardDialogContent';
}


/**
 * @param {!$updateOwnerDashboardDialog.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 */
const $updateOwnerDashboardDialog = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $updateOwnerDashboardDialog$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.dashboardName, opt_data.description, opt_data.owner);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {?} dashboardName
 * @param {?} description
 * @param {?} owner
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $updateOwnerDashboardDialog$ = function($$areYouAnInternalCaller, $ijData, dashboardName, description, owner) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.perspectives.dashboard.TeamscaleDashboardPerspectiveTemplate.updateOwnerDashboardDialog']) {
    return soy.$$stubsMap['ts.perspectives.dashboard.TeamscaleDashboardPerspectiveTemplate.updateOwnerDashboardDialog']({dashboardName: dashboardName, description: description, owner: owner}, $ijData);
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.dashboard.TeamscaleDashboardPerspectiveTemplate.updateOwnerDashboardDialog ts/perspectives/dashboard/TeamscaleDashboardPerspectiveTemplate.soy:268"' : '') + '>' + $soy$ts$commons$UIUtilsTemplate.alerts(null, $ijData) + '<form class="form-horizontal ui form"><div class="field"><label>Dashboard name</label><div class="control-group ui input controls"><input type="text" id="dashboard-name" class="input-xlarge" value="' + soy.$$escapeHtmlAttribute(dashboardName) + '" disabled/></div></div><div class="field"><label>Dashboard owner</label><div class="control-group ui input controls icon"><input id="dashboard-owner" autocomplete="off" type="text" style="width: 200px" value="' + soy.$$escapeHtmlAttribute(owner) + '" placeholder="Enter username" required/><i class="search icon"></i></div></div><div class="field"><label>Change description</label><textarea rows="4" class="input-xlarge" id="change-description" placeholder="Describe the content or the changes">' + soy.$$escapeHtmlRcdata(description) + '</textarea></div></form></div>');
};
export { $updateOwnerDashboardDialog as updateOwnerDashboardDialog };
export { $updateOwnerDashboardDialog$ as updateOwnerDashboardDialog$ };
/**
 * @typedef {{
 *  dashboardName: ?,
 *  description: ?,
 *  owner: ?,
 * }}
 */
$updateOwnerDashboardDialog.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $updateOwnerDashboardDialog.soyTemplateName = 'ts.perspectives.dashboard.TeamscaleDashboardPerspectiveTemplate.updateOwnerDashboardDialog';
}


/**
 * @param {?Object<string, *>=} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $toggleIcons = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.perspectives.dashboard.TeamscaleDashboardPerspectiveTemplate.toggleIcons']) {
    return soy.$$stubsMap['ts.perspectives.dashboard.TeamscaleDashboardPerspectiveTemplate.toggleIcons'](opt_data, $ijData);
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div id="toggle-icons" class="greyLBBorder hoverDim" style="position: absolute; top: -3px; right: 0; background: white; padding-left: 2px; padding-top: 2px;"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.dashboard.TeamscaleDashboardPerspectiveTemplate.toggleIcons ts/perspectives/dashboard/TeamscaleDashboardPerspectiveTemplate.soy:300"' : '') + '><button id="exit-kiosk-button" class="ts-dropdown"' + soy.$$whitespaceHtmlAttributes($soy$ts$commons$UIUtilsTemplate.tooltip$(soy.$$internalCallMarkerDoNotUse, $ijData, 'Exit fullscreen mode')) + '><i class="compress icon"></i></button></div>');
};
export { $toggleIcons as toggleIcons };
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $toggleIcons.soyTemplateName = 'ts.perspectives.dashboard.TeamscaleDashboardPerspectiveTemplate.toggleIcons';
}


/**
 * @param {!$dashboardSharing.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 */
const $dashboardSharing = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $dashboardSharing$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.dashboardName, opt_data.dashboardDescriptor);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {?} dashboardName
 * @param {?} dashboardDescriptor
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $dashboardSharing$ = function($$areYouAnInternalCaller, $ijData, dashboardName, dashboardDescriptor) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.perspectives.dashboard.TeamscaleDashboardPerspectiveTemplate.dashboardSharing']) {
    return soy.$$stubsMap['ts.perspectives.dashboard.TeamscaleDashboardPerspectiveTemplate.dashboardSharing']({dashboardName: dashboardName, dashboardDescriptor: dashboardDescriptor}, $ijData);
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div class="ui container narrow"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.dashboard.TeamscaleDashboardPerspectiveTemplate.dashboardSharing ts/perspectives/dashboard/TeamscaleDashboardPerspectiveTemplate.soy:319"' : '') + '><h4 class="ui header">Configure access rights for ' + soy.$$escapeHtml(dashboardName) + '</h4>' + $dashboardSharingEntity$(soy.$$internalCallMarkerDoNotUse, $ijData, dashboardDescriptor, 'user', 'User') + $dashboardSharingEntity$(soy.$$internalCallMarkerDoNotUse, $ijData, dashboardDescriptor, 'group', 'Group') + $dashboardSharingEntity$(soy.$$internalCallMarkerDoNotUse, $ijData, dashboardDescriptor, 'project', 'Project') + '</div>');
};
export { $dashboardSharing as dashboardSharing };
export { $dashboardSharing$ as dashboardSharing$ };
/**
 * @typedef {{
 *  dashboardName: ?,
 *  dashboardDescriptor: ?,
 * }}
 */
$dashboardSharing.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $dashboardSharing.soyTemplateName = 'ts.perspectives.dashboard.TeamscaleDashboardPerspectiveTemplate.dashboardSharing';
}


/**
 * @param {!$dashboardSharingEntity.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 */
const $dashboardSharingEntity = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $dashboardSharingEntity$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.dashboardDescriptor, opt_data.type, opt_data.typeDisplayName);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {?} dashboardDescriptor
 * @param {?} type
 * @param {?} typeDisplayName
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $dashboardSharingEntity$ = function($$areYouAnInternalCaller, $ijData, dashboardDescriptor, type, typeDisplayName) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.perspectives.dashboard.TeamscaleDashboardPerspectiveTemplate.dashboardSharingEntity']) {
    return soy.$$stubsMap['ts.perspectives.dashboard.TeamscaleDashboardPerspectiveTemplate.dashboardSharingEntity']({dashboardDescriptor: dashboardDescriptor, type: type, typeDisplayName: typeDisplayName}, $ijData);
  }
  let $output = '<table class="ui very basic table"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.dashboard.TeamscaleDashboardPerspectiveTemplate.dashboardSharingEntity ts/perspectives/dashboard/TeamscaleDashboardPerspectiveTemplate.soy:350"' : '') + '><thead><tr><th>' + soy.$$escapeHtml(typeDisplayName) + '</th><th>Access Right</th><th></th></tr></thead><tbody>';
  if (dashboardDescriptor[/** @type {?} */ (type + 'AccessEntries')]) {
    const accessEntry34913List = dashboardDescriptor[/** @type {?} */ (type + 'AccessEntries')];
    const accessEntry34913ListLen = accessEntry34913List.length;
    for (let accessEntry34913Index = 0; accessEntry34913Index < accessEntry34913ListLen; accessEntry34913Index++) {
      const accessEntry34913Data = accessEntry34913List[accessEntry34913Index];
      $output += '<tr><td>' + (soy.$$equals(type, 'user') ? $soy$ts$perspectives$activity$commons$ActivityCommonsTemplate.userFullName$(soy.$$internalCallMarkerDoNotUse, $ijData, accessEntry34913Data[/** @type {?} */ ('resolvedUser')], accessEntry34913Data[/** @type {?} */ ('userOrGroup')]) : soy.$$escapeHtml(accessEntry34913Data[/** @type {?} */ ('userOrGroup')])) + '</td><td>' + soy.$$escapeHtml(accessEntry34913Data[/** @type {?} */ ('accessRight')]) + '</td><td><button data-index=' + soy.$$escapeHtmlAttributeNospace(accessEntry34913Index) + ' class="ts-dropdown edit-' + soy.$$escapeHtmlAttribute(type) + '-right "><i class="edit icon"></i></button><button data-index=' + soy.$$escapeHtmlAttributeNospace(accessEntry34913Index) + ' class="ts-dropdown delete-' + soy.$$escapeHtmlAttribute(type) + '-right "><i class="trash icon"></i></button></td></tr>';
    }
  }
  $output += '<tr><td colspan="3"><button id="add-' + soy.$$escapeHtmlAttribute(type) + '-right" class="ts-dropdown"><i class="add circle icon"></i></button></td></tbody></table><br' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.dashboard.TeamscaleDashboardPerspectiveTemplate.dashboardSharingEntity ts/perspectives/dashboard/TeamscaleDashboardPerspectiveTemplate.soy:390"' : '') + '><br' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.dashboard.TeamscaleDashboardPerspectiveTemplate.dashboardSharingEntity ts/perspectives/dashboard/TeamscaleDashboardPerspectiveTemplate.soy:390"' : '') + '>';
  return soy.VERY_UNSAFE.ordainSanitizedHtml($output);
};
export { $dashboardSharingEntity as dashboardSharingEntity };
export { $dashboardSharingEntity$ as dashboardSharingEntity$ };
/**
 * @typedef {{
 *  dashboardDescriptor: ?,
 *  type: ?,
 *  typeDisplayName: ?,
 * }}
 */
$dashboardSharingEntity.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $dashboardSharingEntity.soyTemplateName = 'ts.perspectives.dashboard.TeamscaleDashboardPerspectiveTemplate.dashboardSharingEntity';
}


/**
 * @param {!$accessEntryEditDialog.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 */
const $accessEntryEditDialog = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $accessEntryEditDialog$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.accessEntry, opt_data.accessEntryName);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {?} accessEntry
 * @param {?} accessEntryName
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $accessEntryEditDialog$ = function($$areYouAnInternalCaller, $ijData, accessEntry, accessEntryName) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.perspectives.dashboard.TeamscaleDashboardPerspectiveTemplate.accessEntryEditDialog']) {
    return soy.$$stubsMap['ts.perspectives.dashboard.TeamscaleDashboardPerspectiveTemplate.accessEntryEditDialog']({accessEntry: accessEntry, accessEntryName: accessEntryName}, $ijData);
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml($soy$ts$commons$UIUtilsTemplate.alerts(null, $ijData) + '<table' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.dashboard.TeamscaleDashboardPerspectiveTemplate.accessEntryEditDialog ts/perspectives/dashboard/TeamscaleDashboardPerspectiveTemplate.soy:403"' : '') + '><tr class="control-group"><td>' + soy.$$escapeHtml(accessEntryName) + ':</td><td><div class="ui input"><input id="userOrGroup-text" autocomplete="off" type="text" style="width: 200px" value="' + soy.$$escapeHtmlAttribute(accessEntry[/** @type {?} */ ('userOrGroup')]) + '"/></div></td></tr><tr class="control-group"><td>Access right:</td><td><select class="ui dropdown" id="access-right-select"><option' + (soy.$$equals(accessEntry[/** @type {?} */ ('accessRight')], 'READ') ? ' selected="selected"' : '') + '>READ</option><option' + (soy.$$equals(accessEntry[/** @type {?} */ ('accessRight')], 'READ_WRITE') ? ' selected="selected"' : '') + '>READ_WRITE</option></select></td></tr></table>');
};
export { $accessEntryEditDialog as accessEntryEditDialog };
export { $accessEntryEditDialog$ as accessEntryEditDialog$ };
/**
 * @typedef {{
 *  accessEntry: ?,
 *  accessEntryName: ?,
 * }}
 */
$accessEntryEditDialog.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $accessEntryEditDialog.soyTemplateName = 'ts.perspectives.dashboard.TeamscaleDashboardPerspectiveTemplate.accessEntryEditDialog';
}


/**
 * @param {!$insufficientAccessRights.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 */
const $insufficientAccessRights = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $insufficientAccessRights$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.dashboardName);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {?} dashboardName
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $insufficientAccessRights$ = function($$areYouAnInternalCaller, $ijData, dashboardName) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.perspectives.dashboard.TeamscaleDashboardPerspectiveTemplate.insufficientAccessRights']) {
    return soy.$$stubsMap['ts.perspectives.dashboard.TeamscaleDashboardPerspectiveTemplate.insufficientAccessRights']({dashboardName: dashboardName}, $ijData);
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div class="ui warning message"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.dashboard.TeamscaleDashboardPerspectiveTemplate.insufficientAccessRights ts/perspectives/dashboard/TeamscaleDashboardPerspectiveTemplate.soy:431"' : '') + '>Insufficient access rights for dashboard <i>' + soy.$$escapeHtml(dashboardName) + '</i>.</div>');
};
export { $insufficientAccessRights as insufficientAccessRights };
export { $insufficientAccessRights$ as insufficientAccessRights$ };
/**
 * @typedef {{
 *  dashboardName: ?,
 * }}
 */
$insufficientAccessRights.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $insufficientAccessRights.soyTemplateName = 'ts.perspectives.dashboard.TeamscaleDashboardPerspectiveTemplate.insufficientAccessRights';
}


/**
 * @param {!$dashboardNotFound.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 */
const $dashboardNotFound = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $dashboardNotFound$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.dashboardName);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {?} dashboardName
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $dashboardNotFound$ = function($$areYouAnInternalCaller, $ijData, dashboardName) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.perspectives.dashboard.TeamscaleDashboardPerspectiveTemplate.dashboardNotFound']) {
    return soy.$$stubsMap['ts.perspectives.dashboard.TeamscaleDashboardPerspectiveTemplate.dashboardNotFound']({dashboardName: dashboardName}, $ijData);
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div class="ui error message error-container"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.dashboard.TeamscaleDashboardPerspectiveTemplate.dashboardNotFound ts/perspectives/dashboard/TeamscaleDashboardPerspectiveTemplate.soy:442"' : '') + '><p><b>Dashboard <i>' + soy.$$escapeHtml(dashboardName) + '</i> was not found.</b></p><p>See the top bar for available dashboards.</p></div>');
};
export { $dashboardNotFound as dashboardNotFound };
export { $dashboardNotFound$ as dashboardNotFound$ };
/**
 * @typedef {{
 *  dashboardName: ?,
 * }}
 */
$dashboardNotFound.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $dashboardNotFound.soyTemplateName = 'ts.perspectives.dashboard.TeamscaleDashboardPerspectiveTemplate.dashboardNotFound';
}


/**
 * @param {!$dashboardNotShared.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 */
const $dashboardNotShared = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $dashboardNotShared$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.dashboardName);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {?} dashboardName
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $dashboardNotShared$ = function($$areYouAnInternalCaller, $ijData, dashboardName) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.perspectives.dashboard.TeamscaleDashboardPerspectiveTemplate.dashboardNotShared']) {
    return soy.$$stubsMap['ts.perspectives.dashboard.TeamscaleDashboardPerspectiveTemplate.dashboardNotShared']({dashboardName: dashboardName}, $ijData);
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div class="ui info message"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.dashboard.TeamscaleDashboardPerspectiveTemplate.dashboardNotShared ts/perspectives/dashboard/TeamscaleDashboardPerspectiveTemplate.soy:456"' : '') + '><p><b>Sorry, you are not able to access Dashboard <i>' + soy.$$escapeHtml(dashboardName) + '</i>!</b></p><p>The owner of the requested dashboard or another user with sufficient rights(<i>READ_WRITE</i>) has to share it with you.</p><p><b>Instructions:</b><br>An authorized user has to open the dropdown menu for <i>' + soy.$$escapeHtml(dashboardName) + '</i> in the sidebar. After selecting the menu item <i>"Sharing..."</i> the authorized user can grant the required access rights(<i>READ</i>) to watch Dashboard <i>' + soy.$$escapeHtml(dashboardName) + '</i> to your account or a whole group of users.</p></div>');
};
export { $dashboardNotShared as dashboardNotShared };
export { $dashboardNotShared$ as dashboardNotShared$ };
/**
 * @typedef {{
 *  dashboardName: ?,
 * }}
 */
$dashboardNotShared.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $dashboardNotShared.soyTemplateName = 'ts.perspectives.dashboard.TeamscaleDashboardPerspectiveTemplate.dashboardNotShared';
}
